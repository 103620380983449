import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';

import { DropdownButton, Dropdown } from 'react-bootstrap';
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  CardTitle,
  Modal,
  ModalBody,
  Table,
} from 'reactstrap';

import ReactTable from '../../../../components/ReactTable/ReactTable';
import { AppState } from '../../../../store/store';
import ApiCaller from '../../../../lib/ApiCaller';
import NavigationExamApi from '../../../../api/NavigationExamApi';
import {
  DEFAULT_TIMEZONE_FORMAT,
  formatDateTime,
  sortDateTable,
} from '../../../../shared/utils/dateTime';
import MYDocument, { MYDocumentStatus } from '../../../../lib/common/models/myDocument';
import Exam, { ExamContentElement } from '../../../../lib/common/models/exam';
import { downloadExamByDocumentDisplayName } from '../../../../shared/utils/ApiCommands';

import 'react-toastify/dist/ReactToastify.css';
import ConfirmationModal from 'views/components/confirmationModal/confirmationModal';
import LoadingSpinner from 'views/components/loadingSpinner/loadingSpinner';

const ExamDocumentArchive: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const [exams, setExams] = useState<MYDocument[]>([]);
  const [myDocument, setMyDocument] = useState<MYDocument>();
  const [isRevertConfirmationModalOpen, setIsRevertConfirmationModalOpen] = useState<boolean>(false);
  const [isQuestionsModalOpen, setIsQuestionsModalOpen] = useState(false);
  const [currentExam, setCurrentExam] = useState<Exam>();
  const [loading, setLoading] = useState<boolean>(false); // Control spinner on/off
  const [loadingTip, setLoadingTip] = useState<string>('');

  const navigationExamApiToken = new NavigationExamApi(
    new ApiCaller(loggedUser.token)
  );

  const navigationExamApi = new NavigationExamApi(
    new ApiCaller()
  );

  useEffect(() => {
    setLoadingTip('loading exams...');
    setLoading(true);
    navigationExamApi.getArchives(loggedUser.token).then((exams) => {
      setExams(exams);
    }).catch((error) => { 
      toast.error(error.message);
    }).finally(() => {
      setLoading(false);
    });
  }, []);
  


  const openQuestions = (sourceSlug: string) => {
    setLoadingTip('loading exam questions...');
    setLoading(true);
    navigationExamApiToken.getExamModelBySlug(sourceSlug).then((exam) => {
      setCurrentExam(exam);
      setIsQuestionsModalOpen(true);
    }).catch((error) => {
      toast.error(error.message);
    }).finally(() => {
      setLoading(false);
    });
  };

  const getExamQuestions = useCallback(() => {
    if (currentExam && currentExam.examContent.content) {
      return currentExam.examContent.content.map((e: ExamContentElement) => (
        <tr key={e.questionName}>
          <td>{e.questionName}</td>
          <td>{e.topicName}</td>
          <td className="text-right">{e.marker}</td>
        </tr>
      ));
    }
  }, [currentExam]);

  /**
   * Revert to draft functions
   */

  const onRevertCallback = (doc: MYDocument) => {
    setIsRevertConfirmationModalOpen(true);
    setMyDocument(doc);
  };

  const revertDocument = (doc: MYDocument | undefined): void => {
    if (doc) {
      setLoading(true);
      setLoadingTip('Reverting document to draft...');
      navigationExamApiToken
        .revertToDraft(doc.customName as string)
        .then(() => {
          // Rmoves from this doc from main list
          setExams(exams.filter((e) => e.customName !== doc.customName));
          toast.success('Document has been reverted to draft');
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          setIsRevertConfirmationModalOpen(false);
          setLoading(false);
        });
    }
  };

  const columns = [
    { Header: 'SERIAL NO.', accessor: (d: any) => d.serialNumber ? d.serialNumber : '---', sortable: true },
    { Header: 'EXAM ID', accessor: (d: any) => d.customName, sortable: true },
    {
      Header: 'EXAM CENTRE',
      accessor: (d: any) => d.customDescription,
      sortable: true,
    },
    { Header: 'STATUS', accessor: (d: any) => d.status, sortable: true },
    {
      Header: 'CREATE DATE',
      accessor: (d: any) =>
        formatDateTime(d.createdAt, DEFAULT_TIMEZONE_FORMAT),
      sortable: true,
      sortType: (a: any, b: any) => sortDateTable(a, b, 'createdAt'),
    },
    {
      Header: 'ACTIONS',
      Cell: (row: any) => (
        <DropdownButton variant="default" title="Actions" size="sm">
          <Dropdown.Item
            onClick={() =>
              downloadExamByDocumentDisplayName(
                row.row.original.customName,
                row.row.original.customName,
              )
            }>
            <i className="nc-icon nc-cloud-download-93" />
            &nbsp;&nbsp;DOWNLOAD
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => openQuestions(row.row.original.sourceSlug)}>
            <i className="nc-icon nc-bullet-list-67" />
            &nbsp;&nbsp;QUESTIONS
          </Dropdown.Item>
          {loggedUser.hasRevertExamPermissions() &&
          row.row.original.status === MYDocumentStatus.DONE && (
            <Dropdown.Item
              onClick={() => {
                const doc = row.row.original;
                onRevertCallback(doc);
              }}>
              <i className="nc-icon nc-refresh-69" />
              &nbsp;&nbsp;REVERT TO DRAFT
            </Dropdown.Item>
          )}
        </DropdownButton>
      ),
    },
  ];

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">{'Exams Archive'}</CardTitle>
              </CardHeader>
              <CardBody>
                <LoadingSpinner spinning={loading} tip={loadingTip}>
                {exams && <ReactTable data={exams} columns={columns} />}
                </LoadingSpinner>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </div>
      <Modal isOpen={isQuestionsModalOpen}>
        <div className="modal-header">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setIsQuestionsModalOpen(false)}>
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h5 className="card-title">Questions</h5>
        </div>
        <ModalBody>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Topic</th>
                <th className="text-right">Marker</th>
              </tr>
            </thead>
            <tbody>{getExamQuestions()}</tbody>
          </Table>
        </ModalBody>
      </Modal>
      {isRevertConfirmationModalOpen && (
        <ConfirmationModal
            title="Revert document confirmation"
            onConfirm={() => revertDocument(myDocument)}
            onCancel={() => setIsRevertConfirmationModalOpen(false)}
            text="Are you sure you want to revert this document to draft?"
        />
      )}
    </>
  );
};

export default ExamDocumentArchive;
