import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import moment from 'moment';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';

import { useClearListStateOnPageChange, useTableControls } from 'hooks';
import CandidateCollection from 'lib/common/models/candidateCollection';
import 'react-toastify/dist/ReactToastify.css';
import { stateListNames } from 'store/reducers/listsReducer';
import { capitalizeFirstLetter } from 'utils/utils';
import LoadingSpinner from 'views/components/loadingSpinner/loadingSpinner';
import CandidateApi from '../../../../api/CandidateApi';
import ReactTable from '../../../../components/ReactTable/ReactTable';
import ApiCaller from '../../../../lib/ApiCaller';
import Candidate, {
  CandidateStatus,
} from '../../../../lib/common/models/candidate';
import Settings from '../../../../lib/settings';
import { AppState } from '../../../../store/store';
import CandidateBatchImport from '../../../components/candidate/candidateBatchImport';
import CandidateModal from './candidateModal';

const CandidateList: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const layout = useSelector((state: AppState) => state.session.layout);
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingTip, setLoadingTip] = useState<string>('');

  const [candidates, setCandidates] = React.useState<CandidateCollection>(
    new CandidateCollection([]),
  );

  const [modalCreate, setModalCreate] = React.useState<boolean>(false);
  const [candidateForm, setCandidateForm] = React.useState<Candidate>(
    Candidate.prototype.emptyCandidate(),
  );

  const [modalImport, setModalImport] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);

  const listName = stateListNames.candidateList;
  const {
    filters,
    sorting,
    pagination,
    onChangeFilters,
    onChangeSorting,
    onChangePagination,
  } = useTableControls(listName);

  useClearListStateOnPageChange(listName, [
    '/candidate/list',
    '/candidate/detail',
  ]);

  useEffect(() => {
    setLoadingTip('loading candidates...');
    setLoading(true);
    const candidateApi = new CandidateApi(new ApiCaller(loggedUser.token));
    candidateApi
      .getAllCandidates()
      .then((list: CandidateCollection) => {
        if (list.length() > 0) {
          setCandidates(list);
          toast.success('All candidates loaded successfully');
        }
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        console.error(error);
        toast.error('Error loading candidates');
      });
  }, [refresh]);

  const chooseCandidate = (cell: any) => {
    const candidate = cell.row.original;
    Settings.setCurrentCandidate(candidate);
    history.push(`/${layout}/candidate/detail`);
  };

  const columns = [
    {
      Header: 'SERIAL NO.',
      accessor: (d: any) => (d.serialNumber ? d.serialNumber : '-'),
      sortable: true,
    },
    { Header: 'NAME', accessor: (d: any) => d.fullName(), sortable: true },
    {
      Header: 'BIRTH DATE',
      accessor: (d: any) => moment(d.birthDate).utc().format('DD-MM-YYYY'),
    },
    { Header: 'PASSPORT NO.', accessor: 'passportNumber', sortable: true },
    { Header: 'EMAIL', accessor: 'email', sortable: true },
    {
      Header: 'EMAIL STATUS',
      accessor: 'status',
      sortable: true,
      Cell: ({ value }: { value: CandidateStatus }) =>
        // Not a fan, but changing the ENUM may mean changing the DB and a mass update would ensue, which I'm less of a fan, so for presentional purposes only
        capitalizeFirstLetter(
          value === CandidateStatus.PENDING ? 'Pending Confirmation' : value,
        ),
    },
    {
      Header: 'LEARNING DIFF.',
      accessor: (d: any) => (d.special ? 'Yes' : 'No'),
      sortable: true,
    },
    {
      Header: 'ACTIONS',
      Cell: (row: any) => {
        return (
          <>
            <div>
              <Button
                className="btn-round"
                color="info"
                onClick={() => {
                  chooseCandidate(row);
                }}>
                VIEW
              </Button>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Candidates List</CardTitle>
              </CardHeader>
              <CardBody>
                {loggedUser.hasCandidatePermissions() && (
                  <Row>
                    <Col>
                      <Button color="info" onClick={() => setModalCreate(true)}>
                        <span>
                          <i className="nc-icon nc-simple-add" />
                        </span>
                        Create
                      </Button>
                      <Button
                        onClick={() => setModalImport(true)}
                        color="success"
                        className="btn-label"
                        disabled={!loggedUser.hasCandidatePermissions()}>
                        <span>
                          <i className="nc-icon nc-cloud-upload-94" />
                        </span>
                        Upload Excel
                      </Button>
                    </Col>
                  </Row>
                )}
                <LoadingSpinner spinning={loading} tip={loadingTip}>
                  <ReactTable
                    data={candidates?.candidates}
                    columns={columns}
                    initialFilters={filters}
                    onChangeFilters={onChangeFilters}
                    initialSorting={sorting}
                    onChangeSorting={onChangeSorting}
                    initialPagination={pagination}
                    onChangePagination={onChangePagination}
                  />
                </LoadingSpinner>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <CandidateModal
          modal={modalCreate}
          setModal={setModalCreate}
          candidates={candidates}
          setCandidates={setCandidates}
          candidateForm={candidateForm}
          setCandidateForm={setCandidateForm}
        />

        <Modal isOpen={modalImport}>
          <ModalHeader>Import Candidates Excel</ModalHeader>
          <ModalBody>
            <CandidateBatchImport
              setModalImport={setModalImport}
              collection={candidates}
              setCollection={setCandidates}
              setRefresh={setRefresh}
            />
          </ModalBody>
        </Modal>
      </div>
      <ToastContainer />
    </>
  );
};

export default CandidateList;
